table.pvtTable thead tr th {
  background-color: #001EFF;
  color: white;
}

table.pvtTable tbody tr th {
  background-color: white;
  color: black;
}

.pvtAxisContainer, .pvtVals {
  background-color: white;
  color: black;
}

.pvtAttr {
  background-color: white !important;
  color: black;
}

.pvtRenderers {
  display: none;
}

.container-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.container-buttons button, .pvtButton {
  background-color: white;
  color: black;
  border-color: black;
  border-width: thin;
  border-radius: 0.25rem;
  cursor: pointer;
}

.container-buttons button:disabled {
  background-color: #d3d3d3;
  color: #7a7a7a;
  border: 1px solid #c0c0c0;
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
}

.pvtCheckContainer p.selected {
  background-color: #efefef;
  color: black;
}

.card-body {
  padding: 15px 15px 0;
}

.pvtRowOrder, .pvtColOrder {
  display: none !important;
}
